import React, { useState } from "react";
import {
  Building2,
  Users,
  HeartHandshake,
  Shield,
  ChevronDown,
  ChevronUp,
  GraduationCap,
  Briefcase,
  Trophy,
  History,
} from "lucide-react";

import {
  PageContainer,
  AboutContainer,
  Sidebar,
  SidebarHeader,
  NavItem,
  ContentSection,
  ContentTitle,
  ContentText,
  ProfileHeader,
  AccordionHeader,
  AccordionContent,
  ProfileImage,
  ValueSection,
  ValueTitle,
  HealthSafetyContainer,
  IconContainer,
  ProfileCard,
  ProfileName,
  ProfileRole,
  AccordionGroup,
  ManagementGrid,
} from "./AboutViewStyles";
import "./About.css";
import ceo_img from "../../assets/images/ceo.png";
import technical_director_img from "../../assets/images/technical_director_img.png";

const MANAGEMENT_TEAM = [
  {
    id: 1,
    name: "BONIFACIUS K KATANGA",
    role: "CEO/Founder",
    image: ceo_img,
    academics: "B.Sc. Chemistry & Geology, ArcGIS cert.",
    experience: {
      years: "14 Years",
      commodities:
        "Base & rare metals (REE, Lithium), Industrial minerals, semi-precious & Precious stones, Nuclear Fuels & precious metals.",
    },
    successfulProjects: [
      "Lofdal HREE from discovery to PEA",
      "WIA Gold (Okombahe), Navachab Gold Mine",
      "Uis Tin Mine, Epembe Nb, Ta deposit",
      "Ondoto/Swartbooisdrift LREE (discovery)",
      "Teufelskuppe LREE, Ongombo Au, Cu, & Ag deposit",
      "Witvlei Cu, Au, Ag & DOF Cobalt",
    ],
    preCareerExperience:
      "Mr Katanga is a professional geologist with over 14 years of extensive experience in mineral exploration. He has been involved with numerous green, to brown field projects, some of which he participated and led, from discovery to feasibility studies. His experience covers a large spectrum of commodities, especially with those of clean and green energy, including nuclear fuels, and wealth of experience in base and/or precious metals.  Katanga is a technically oriented geologist whose focus is to see projects move forward to the next level, and he has led, supervise and executed many resource definition drilling programs for JORC2012 certification, working with qualified or competent professionals including highly recognised mineral firms.",
  },
  {
    id: 2,
    name: "GIDEON K HAINGURA",
    role: "Technical Director",
    image: technical_director_img,
    academics: "M.Sc. Mining Engineering, PMP Certification",
    experience: {
      years: "10 Years",
      commodities:
        "Gold, Copper, Silver, Strategic minerals, Rare earth elements",
    },
    successfulProjects: [
      "Namibian Gold Project Phase II",
      "Copper Belt Exploration Program",
      "Strategic Minerals Assessment",
      "Rare Earth Elements Mapping Initiative",
    ],
    preCareerExperience:
      "Mr. Gideon K Haingura is a professional geologist and GIS/Remote Sensing specialist with more than 13 years of experience in green and brownfields projects in mineral exploration, reconnaissance, project management and supervision, and project coordination. He was one of the pioneer geologists who led Lofdal HREE project from discovery to pre-feasibility studies and was involved in several mineral deposit discoveries including Ondoto Rare Earths in northwestern Namibia. His most recent and current responsibilities include country project coordinator, representing Namibia on TIMS project under ECSA-HC/SADC, as well as research associate at the Namibia University of Science and Technology, in application of Artificial Intelligence in; wildlife monitoring technology and innovation as well as real-time platform administrator, Earth-Ranger and African wildlife tracking. He is an active participant in Intensive Research (IRES) programs organised by several universities in USA. Mineral investor advisor,agent, presenter, EIA, and licensing applications/renewals consultant. Mr Gideon serves as technical manager and advisor with several corporations in Namibia and he is registered as a senior geoscientist with the Geoscience Council of Namibia. ",
  },
];

const AboutView = () => {
  const [selectedSection, setSelectedSection] = useState("about-us");
  const [accordions, setAccordions] = useState(
    MANAGEMENT_TEAM.reduce(
      (acc, member) => ({
        ...acc,
        [member.id]: {
          academics: false,
          experience: false,
          successfulProjects: false,
          preCareerExperience: false,
        },
      }),
      {}
    )
  );

  const toggleAccordion = (memberId, key) => {
    setAccordions((prev) => ({
      ...prev,
      [memberId]: {
        ...prev[memberId],
        [key]: !prev[memberId][key],
      },
    }));
  };

  const renderProfile = (member) => (
    <ProfileCard key={member.id}>
      <ProfileHeader>
        <ProfileImage src={member.image} alt={member.name} />
        <ProfileName>{member.name}</ProfileName>
        <ProfileRole>{member.role}</ProfileRole>
      </ProfileHeader>

      <AccordionGroup>
        <AccordionHeader
          onClick={() => toggleAccordion(member.id, "academics")}
        >
          <IconContainer>
            <GraduationCap size={20} />
            <span>Academics</span>
          </IconContainer>
          {accordions[member.id].academics ? <ChevronUp /> : <ChevronDown />}
        </AccordionHeader>
        {accordions[member.id].academics && (
          <AccordionContent>
            <ContentText>{member.academics}</ContentText>
          </AccordionContent>
        )}

        <AccordionHeader
          onClick={() => toggleAccordion(member.id, "experience")}
        >
          <IconContainer>
            <Briefcase size={20} />
            <span>Experience</span>
          </IconContainer>
          {accordions[member.id].experience ? <ChevronUp /> : <ChevronDown />}
        </AccordionHeader>
        {accordions[member.id].experience && (
          <AccordionContent>
            <ContentText>
              <strong>Years:</strong> {member.experience.years}
            </ContentText>
            <ContentText>
              <strong>Commodities:</strong> {member.experience.commodities}
            </ContentText>
          </AccordionContent>
        )}

        <AccordionHeader
          onClick={() => toggleAccordion(member.id, "successfulProjects")}
        >
          <IconContainer>
            <Trophy size={20} />
            <span>Successful Projects</span>
          </IconContainer>
          {accordions[member.id].successfulProjects ? (
            <ChevronUp />
          ) : (
            <ChevronDown />
          )}
        </AccordionHeader>
        {accordions[member.id].successfulProjects && (
          <AccordionContent>
            <ContentText>
              {member.successfulProjects.map((project, index) => (
                <div key={index}>• {project}</div>
              ))}
            </ContentText>
          </AccordionContent>
        )}

        <AccordionHeader
          onClick={() => toggleAccordion(member.id, "preCareerExperience")}
        >
          <IconContainer>
            <History size={20} />
            <span>Pre-career Experience</span>
          </IconContainer>
          {accordions[member.id].preCareerExperience ? (
            <ChevronUp />
          ) : (
            <ChevronDown />
          )}
        </AccordionHeader>
        {accordions[member.id].preCareerExperience && (
          <AccordionContent>
            <ContentText>{member.preCareerExperience}</ContentText>
          </AccordionContent>
        )}
      </AccordionGroup>
    </ProfileCard>
  );
  const renderAboutUs = () => (
    <>
      <ContentTitle>Boron GeoScience</ContentTitle>
      <ContentText>
        A Namibian based independent mineral exploration close corporation
        consultancy with primary focus on the technical project planning,
        budgeting, management and operational execution for mineral resource
        discoveries, mineral rights applications and filling of statutory
        documents for mineral developers.
      </ContentText>
      <ContentText>
        BGS is well situated to helping your corporation meet its objectives and
        operate smoothly in country, ensuring all statutory obligations and
        stakeholders needs are met.
      </ContentText>
      <ContentText>
        Visit our services page for more information on what BGS can offer your
        corporation. Explore with us and give your corporation a chance to
        succeed.
      </ContentText>
    </>
  );

  const renderManagement = () => (
    <>
      <ContentTitle>Management Team</ContentTitle>
      <ManagementGrid>
        {MANAGEMENT_TEAM.map((member) => renderProfile(member))}
      </ManagementGrid>
    </>
  );

  const renderValues = () => (
    <>
      <ContentTitle>Our Values</ContentTitle>

      <ContentText>
        BGS is underpinned by three beneficial values that aim to enhance good
        work ethics to ensure positive change is achieved via sound loyalty,
        integrity and commitment- whose beneficiaries are our team members,
        clients and communities we operate in.
      </ContentText>

      <ValueSection>
        <ValueTitle>HRD | Human Resource Development</ValueTitle>
        <ContentText>
          Human resource development is highly encouraged for there is no great
          reward in idleness but through hard work, dedication and constant
          improvements. Worry not for we consider your circumstance with fellow
          feeling. Therefore, we urge our partners personal advancement to be
          driven at all times by honour, respect and dependability. The good
          condition of heart and happiness of our people is very dear to us
        </ContentText>
      </ValueSection>

      <ValueSection>
        <ValueTitle>Hard Work and Excellence</ValueTitle>
        <ContentText>
          Hard work pays as the saying goes but excellence is even higher
          principle for it reminds us to strive for greatness to surpass the
          expectations of our clients, guaranteeing superior service delivery.
          BGS bares in mind professional development, enhancements of
          capabilities by being creative in our approaches to challenging
          circumstances. Change is in effect brought by adaptability to ever
          changing circumstances in the mineral world.
        </ContentText>
      </ValueSection>

      <ValueSection>
        <ValueTitle>Dependability</ValueTitle>
        <ContentText>
          Dependability is a moral principle we strongly live by and guide our
          actions. It helps us deliver quality work and take pride in being
          accountable for what we do which result in a motivated and ever
          enduring team. We shift no blame on no one when pitfalls manifests in
          the course of the programme but rather stay oriented to fine tuning
          our oversight to ensure a well-polished and top-notch service
          provision.
        </ContentText>
      </ValueSection>

      <ValueSection>
        <ValueTitle>Benevolence and Love for All</ValueTitle>
        <ContentText>
          BGS emphasizes compassionate, opportunity and fair treatment for
          people of all backgrounds. We believe diversity and inclusiveness
          yields an environment whereby team members take ownership of what is
          under their care and be proud of what they stand for, which in turn
          aid them to achieve their full potential.
        </ContentText>
      </ValueSection>
    </>
  );

  const renderHealthSafety = () => (
    <>
      <ContentTitle>Health & Safety</ContentTitle>
      <HealthSafetyContainer>
        <ContentText>
          BGS highly emphasizes the health & safety of our team members and
          partners. We are committed to high standards of health and safety by
          implementing educative programs in accordance with government safety
          laws and strictly adhere to our clients’ safety standards.
        </ContentText>
        <ContentText>
          Therefore, our philosophy is all our work is guided by “site specific”
          standard operating procedures that clearly outline the hazards
          associated with the activities performed by our team members and
          partners, and well embedded checklist, as well as mitigation measures.
        </ContentText>
      </HealthSafetyContainer>
    </>
  );
  const renderContent = () => {
    switch (selectedSection) {
      case "about-us":
        return renderAboutUs();
      case "management":
        return renderManagement();
      case "values":
        return renderValues();
      case "health-safety":
        return renderHealthSafety();
      default:
        return null;
    }
  };

  return (
    <PageContainer>
      <AboutContainer>
        <Sidebar>
          <SidebarHeader>About Us</SidebarHeader>
          <NavItem
            active={selectedSection === "about-us"}
            onClick={() => setSelectedSection("about-us")}
          >
            <Building2 size={20} />
            BGS
          </NavItem>
          <NavItem
            active={selectedSection === "management"}
            onClick={() => setSelectedSection("management")}
          >
            <Users size={20} />
            Management
          </NavItem>
          <NavItem
            active={selectedSection === "values"}
            onClick={() => setSelectedSection("values")}
          >
            <HeartHandshake size={20} />
            Values
          </NavItem>
          <NavItem
            active={selectedSection === "health-safety"}
            onClick={() => setSelectedSection("health-safety")}
          >
            <Shield size={20} />
            Health & Safety
          </NavItem>
        </Sidebar>
        <ContentSection>{renderContent()}</ContentSection>
      </AboutContainer>
    </PageContainer>
  );
};

export default AboutView;
