import styled from "styled-components";

export const SectionOne = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 2em 2em 1.5em;
  }
`;

export const SectionOneInnerDiv = styled.div`
  margin: 100px 70px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    margin: 80px 20px 0 20px;
  }
`;

export const SectionOneHeading = styled.h1`
  color: white;
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const SectionOneCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px auto;
  max-width: 600px;
  width: 90%;
  border: 2px #031461 solid;
  border-radius: 20px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;

  h3 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    margin: 40px auto;
    padding: 30px;

    h3 {
      font-size: 24px;
    }
  }
`;

export const SectionTwoMain = styled.div`
  margin: 70px auto;
  max-width: 1200px;
  padding: 0 20px;

  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #333;
  }

  @media (max-width: 768px) {
    margin: 50px auto;

    h2 {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
`;

export const SectionTwoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 768px) {
    gap: 30px;
  }
`;

export const SectionTwoCard = styled.div`
  border-radius: 20px;
  border: none;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
  background: white;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
  }
`;

export const SectionTwoCardImg = styled.img`
  border-radius: 20px 20px 0 0;
  height: 240px;
  width: 100%;
  object-fit: cover;
`;

export const SectionTwoCardContent = styled.div`
  padding: 24px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
  }

  p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;

export const ServiceReadMoreButton = styled.button`
  border: 2px solid #2ecc71;
  background-color: transparent;
  color: #2ecc71;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2ecc71;
    color: white;
  }

  .arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }

  &:hover .arrow {
    transform: translate(5px, -50%);
  }
`;

export const SectionThreePallarax = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
  }

  @media (max-width: 768px) {
    padding: 3em 1em;
  }
`;

export const SectionThree = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
    color: #666;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;

    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 18px;
    }
  }
`;

export const SectionThreeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 20px 0;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const SectionThreeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  svg {
    font-size: 40px;
    color: #2ecc71;
    margin-bottom: 20px;
  }

  hr {
    width: 60px;
    border: none;
    border-top: 3px solid #2ecc71;
    margin: 20px 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
  }

  p {
    text-align: center;
    color: #666;
    line-height: 1.6;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export const SectionFour = styled.div`
  @media (max-width: 768px) {
    padding: 3em 1em;
  }
`;

export const SectionFourContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgba(247, 222, 192, 0.7);
  border-radius: 40px;
  padding: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 30px 20px;
    border-radius: 30px;
  }
`;

export const SectionFourDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;

    img {
      height: 300px;
      border-radius: 20px;
    }
  }
`;

export const SectionFourItem = styled.div`
  padding: 20px;

  h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
  }

  @media (max-width: 768px) {
    text-align: center;

    h3 {
      font-size: 26px;
    }

    p {
      font-size: 16px;
    }
  }
`;
