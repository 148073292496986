import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookAtlas,
  faEye,
  faFistRaised,
} from "@fortawesome/free-solid-svg-icons";
import { FaCity, FaDatabase, FaTint } from "react-icons/fa";
import homeImage from "../../assets/images/IMG-20230721-WA0001.jpg";

// Import styled-components
import {
  SectionOne,
  SectionOneInnerDiv,
  SectionOneHeading,
  SectionOneCard,
  SectionTwoMain,
  SectionTwoContainer,
  ServiceReadMoreButton,
  SectionThreePallarax,
  SectionThree,
  SectionThreeContainer,
  SectionThreeCard,
  SectionFour,
  SectionFourContainer,
  SectionFourDiv,
  SectionFourItem,
} from "./HomeStyles";

import {
  CardContainer,
  ServiceCard,
  IconContainer,
  CardTitle,
  ServiceList,
  ListItem,
} from "../services/ServicesStyles";
import "./home.css";

const developmentCards = [
  {
    title: "Urban Development",
    icon: <FaCity />,
    services: [
      "Town planning ",
      "Solid waste management",
      "Civil development & construction",
    ],
  },
  {
    title: "Data Management",
    icon: <FaDatabase />,
    services: [
      "Server setup",
      "Data source setup",
      "Dictionary and restriction setup",
    ],
  },
  {
    title: "Water Resource",
    icon: <FaTint />,
    services: [
      "Hydrogeology",
      "Investigations & Mapping",
      "Aquifer Assessments",
    ],
  },
];
function HomeView() {
  return (
    <>
      {/* Section One */}
      <SectionOne className="SectionOne">
        <SectionOneInnerDiv>
          <SectionOneHeading>
            Unlocking the World's Rare Metal Treasures
          </SectionOneHeading>
          <SectionOneCard>
            <h3>Expert Geological Consultant</h3>
          </SectionOneCard>
        </SectionOneInnerDiv>
      </SectionOne>

      {/* Section Two */}
      <SectionTwoMain>
        <h2>Services Offered at a glance</h2>
        <SectionTwoContainer>
          <CardContainer>
            {developmentCards.map((card, index) => (
              <ServiceCard key={index}>
                <IconContainer>{card.icon}</IconContainer>
                <CardTitle>{card.title}</CardTitle>
                <ServiceList>
                  {card.services.map((service, idx) => (
                    <ListItem key={idx}>{service}</ListItem>
                  ))}
                </ServiceList>
                <br></br>
                <ServiceReadMoreButton>
                  Read More <span style={{ fontSize: "30px" }}>&#8594;</span>
                </ServiceReadMoreButton>
              </ServiceCard>
            ))}
          </CardContainer>
        </SectionTwoContainer>
      </SectionTwoMain>

      {/* Section Three */}
      <SectionThreePallarax className="SectionThreePallarax">
        <SectionThree>
          <h1>About us</h1>
          <h3>
            Rare Metals Explorers (RME) cc is a Namibian registered close
            corporation, and it is one of the trusted consultancies for mineral
            exploration and mining.
          </h3>
          <SectionThreeContainer>
            <SectionThreeCard>
              <FontAwesomeIcon
                icon={faBookAtlas}
                style={{ fontSize: "30px" }}
              />
              <hr />
              <h2>Mission</h2>
              <p>
                Our passionate and proficient team of geologists have managed
                both private.
              </p>
            </SectionThreeCard>
            <SectionThreeCard>
              <FontAwesomeIcon icon={faEye} style={{ fontSize: "30px" }} />
              <hr />
              <h2>Vision</h2>
              <p>
                We aim to be the leading consultancy for rare metal exploration
                and development.
              </p>
            </SectionThreeCard>
            <SectionThreeCard>
              <FontAwesomeIcon
                icon={faFistRaised}
                style={{ fontSize: "30px" }}
              />
              <hr />
              <h2>Achievements</h2>
              <p>
                Our team handled the geological aspects of the Lofdal REE
                deposit from 2013 to 2017.
              </p>
            </SectionThreeCard>
          </SectionThreeContainer>
        </SectionThree>
      </SectionThreePallarax>
      {/* Section Four */}
      <SectionFour className="SectionFour">
        <SectionFourContainer>
          <SectionFourDiv>
            <img src={homeImage} alt="rare metal explorers" />
            <SectionFourItem>
              <h3>Boron GeoScience Service cc</h3>
              <p>
                We specialize in green technology metals such as REEs and
                Lithium.
              </p>
            </SectionFourItem>
          </SectionFourDiv>
        </SectionFourContainer>
      </SectionFour>
    </>
  );
}

export default HomeView;
