import styled from "styled-components";

export const ServiceSection = styled.div`
  padding: 80px 20px;
  max-width: 1400px;
  margin: 60px auto auto auto;
`;

export const MainHeading = styled.h2`
  font-size: 48px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const SubHeading = styled.h4`
  font-size: 28px;
  font-weight: 500;
  color: #666;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 30px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 40px 0;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const ServiceCard = styled.div`
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #eee;
  position: relative;
  overflow: hidden;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #2ecc71, #3498db);
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
`;

export const IconContainer = styled.div`
  font-size: 36px;
  color: #2ecc71;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.3s ease;
  }

  ${ServiceCard}:hover & svg {
    transform: scale(1.1);
  }
`;

export const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    color: #2ecc71;
  }
`;

export const ServiceList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 12px;
  padding-left: 20px;
  position: relative;
  color: #666;
  font-size: 15px;
  line-height: 1.5;

  &::before {
    content: "•";
    color: #2ecc71;
    position: absolute;
    left: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LoggingSection = styled.div`
  background: white;
  border-radius: 20px;
  padding: 40px;
  margin: 60px 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const LoggingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const LoggingColumn = styled.div``;

export const DevelopmentSection = styled.div`
  margin-top: 80px;
`;
