import styled from "styled-components";

export const PageContainer = styled.div`
  max-width: 1440px;
  margin: 150px auto 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 1fr) 3fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const Sidebar = styled.nav`
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  height: fit-content;
`;

export const SidebarHeader = styled.div`
  background: brown;
  color: white;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  font-size: 24px;
  font-weight: 600;
`;

export const NavItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  border: none;
  background: none;
  color: ${(props) => (props.active ? "brown" : "#666")};
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid #eee;

  &:hover {
    background: #f8f8f8;
    color: brown;
  }

  svg {
    margin-right: 12px;
  }
`;

export const ContentSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
`;

export const ContentTitle = styled.h1`
  color: brown;
  font-size: 32px;
  margin-bottom: 24px;
  font-weight: 600;
`;

export const ContentText = styled.p`
  color: #666;
  line-height: 1.8;
  font-size: 16px;
  margin-bottom: 20px;
`;

export const AccordionSection = styled.div`
  margin-top: 24px;
`;

export const AccordionHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: #f8f8f8;
  border: none;
  border-radius: 8px;
  color: brown;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f0f0f0;
  }

  svg {
    margin-right: 12px;
  }
`;

export const AccordionContent = styled.div`
  padding: 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #eee;
`;

// export const ProfileImage = styled.img`
//   width: 200px;
//   height: 200px;
//   border-radius: 50%;
//   margin-bottom: 24px;
//   object-fit: cover;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
// `;

export const ValueSection = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ValueTitle = styled.h2`
  color: brown;
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const HealthSafetyContainer = styled.div`
  background: #f8f8f8;
  padding: 24px;
  border-radius: 12px;
  margin-top: 24px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const ManagementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
  margin-bottom: 40px;
`;

export const ProfileCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
`;

export const ProfileImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

export const ProfileName = styled.h3`
  color: brown;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ProfileRole = styled.h4`
  color: #666;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const AccordionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
