import React from "react";
import {
  FaMap,
  FaVial,
  FaCogs,
  FaProjectDiagram,
  FaLeaf,
  FaDatabase,
  FaClipboardCheck,
  FaCity,
  FaTree,
  FaTint,
} from "react-icons/fa";
import {
  ServiceSection,
  MainHeading,
  SubHeading,
  CardContainer,
  ServiceCard,
  IconContainer,
  CardTitle,
  ServiceList,
  ListItem,
  LoggingSection,
  LoggingContainer,
  LoggingColumn,
  DevelopmentSection,
} from "./ServicesStyles";

const ServicesView = () => {
  const serviceCards = [
    {
      title: "Regional & Detailed Geological Mapping",
      icon: <FaMap />,
      services: [
        "Trench Mapping",
        "Regolith/Scree/Gravel Mapping",
        "Alteration Mapping",
        "Structural Mapping",
        "Report writing",
        "Personnel training",
      ],
    },
    {
      title: "Regional & Detailed Geochemical Survey",
      icon: <FaVial />,
      services: [
        "Termite Mounts Sampling",
        "Stream Sediments",
        "Panning",
        "Personnel training",
      ],
    },
    {
      title: "Drill Program Design & Supervision",
      icon: <FaCogs />,
      services: [
        "Workflow & communication design",
        "Drill fence planning & design",
        "Oversight & coordination",
        "Personnel training",
      ],
    },
    {
      title: "Project Management & Budgeting",
      icon: <FaProjectDiagram />,
      services: [
        "Office Administration",
        "ArcGIS administration",
        "System administration",
        "Technical advisory",
      ],
    },
    {
      title: "EIA & EMP",
      icon: <FaLeaf />,
      services: [
        "Equipment deployment",
        "Data collection oversight & coordination",
        "Recommendation & report writing",
      ],
    },
    {
      title: "Data Management",
      icon: <FaDatabase />,
      services: [
        "Server setup",
        "Data source setup",
        "Dictionary and restriction setup",
        "Redundancy checks",
        "Presentation setup",
      ],
    },
  ];

  const developmentCards = [
    {
      title: "Urban Development",
      icon: <FaCity />,
      services: [
        "Town planning (Street layout & land use design)",
        "Solid waste management",
        "Civil development & construction",
      ],
    },
    {
      title: "Rural Development",
      icon: <FaTree />,
      services: [
        "Environmental & Wildlife Conservation Awareness",
        "Community Engagement & Strategic planning",
        "Water Well Sitting, Installation & Decommissioning",
        "Civil Construction",
        "Heritage Impact Assessments",
      ],
    },
    {
      title: "Water Resource",
      icon: <FaTint />,
      services: [
        "Hydrogeology",
        "Investigations & Mapping",
        "Aquifer Assessments",
        "Source Water Assessment",
        "Contamination & Transport",
        "Groundwater Sampling & Monitoring",
        "Database Development & Statistical Data Analysis",
      ],
    },
  ];

  return (
    <ServiceSection>
      <MainHeading>Ministrations</MainHeading>
      <SubHeading>Mineral Exploration and Mining</SubHeading>

      <CardContainer>
        {serviceCards.map((card, index) => (
          <ServiceCard key={index}>
            <IconContainer>{card.icon}</IconContainer>
            <CardTitle>{card.title}</CardTitle>
            <ServiceList>
              {card.services.map((service, idx) => (
                <ListItem key={idx}>{service}</ListItem>
              ))}
            </ServiceList>
          </ServiceCard>
        ))}
      </CardContainer>

      <LoggingSection>
        <CardTitle>
          <FaClipboardCheck />
          Logging, Sampling & QAQC Implementation
        </CardTitle>
        <LoggingContainer>
          <LoggingColumn>
            <ServiceList>
              <ListItem>Data capture documentation</ListItem>
              <ListItem>Procedural design & implementation</ListItem>
              <ListItem>
                Structural logging, analysis and interpretation
              </ListItem>
              <ListItem>Engineering geotechnical logging</ListItem>
              <ListItem>Detailed logging of mineralized zones</ListItem>
            </ServiceList>
          </LoggingColumn>
          <LoggingColumn>
            <ServiceList>
              <ListItem>Channel sampling (inclusive of grinding)</ListItem>
              <ListItem>Trench sampling</ListItem>
              <ListItem>
                QAQC implementation compatible with JORC-2012 code data
                validation
              </ListItem>
              <ListItem>Channel sampling (inclusive of grinding)</ListItem>
              <ListItem>Report writing</ListItem>
            </ServiceList>
          </LoggingColumn>
        </LoggingContainer>
      </LoggingSection>

      <DevelopmentSection>
        <SubHeading>Open pit & Underground Grade Control</SubHeading>
        <CardContainer>
          {developmentCards.map((card, index) => (
            <ServiceCard key={index}>
              <IconContainer>{card.icon}</IconContainer>
              <CardTitle>{card.title}</CardTitle>
              <ServiceList>
                {card.services.map((service, idx) => (
                  <ListItem key={idx}>{service}</ListItem>
                ))}
              </ServiceList>
            </ServiceCard>
          ))}
        </CardContainer>
      </DevelopmentSection>
    </ServiceSection>
  );
};

export default ServicesView;
